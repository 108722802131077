<template>
  <div class="test-info-inner">
    <div class="test-info-inner-line">
      <p>旧机机型：</p>
      <p v-if="!datainfo.isElderlyMachine" class="test-info-inner-green lv_ycyc1">{{ datainfo.machineName || '--' }}</p>
      <p v-else class="test-info-inner-green lv_ycyc1">其他型号</p>
      <!-- 20231130修改：是渠道（SDBD即outType为lb和lb2）不显示 使用情况 -->
      <template v-if="externalorderType !=='lb' && externalorderType !=='lb2'">
        <p style="margin-left: 129px">旧机使用情况：</p>
        <p class="test-info-inner-green lv_ycyc1" style="border-right: 0" v-if="datainfo.isNew">全新未拆封</p>
        <p class="test-info-inner-green lv_ycyc1" style="border-right: 0" v-else-if="datainfo.isElderlyMachine">
          功能机/老年机
        </p>
        <p v-else :class="['test-info-inner-green', 'lv_ycyc1', datainfo.canStartingUp ? '' : 'title_color_err']"
          style="border-right: 0">
          {{ datainfo.canStartingUp ? '正常开机' : '无法开机' }}
        </p>
      </template>
    </div>
    <div class="test-info-inner-box">
      <div class="test-info-inner-line text_sku_title">
        规&nbsp;格：
      </div>
      <div class="sku_inner">
        <div class="test-info-inner-line bgFFF flex-style" v-if="datainfo.informationList.length">
          <p v-for="(item, index) in datainfo.informationList" :key="index">{{ item.label }}：{{ item.value }}</p>
        </div>
        <!-- 外部订单 -->
        <!-- <div class="test-info-inner-line bgFFF flex-style" v-else-if="skus.length">
          <p v-for="(item,index) in skus" :key="index">{{ item.label }}：{{ item.value[0].valueText}}</p>
        </div> -->
        <div v-else class="empty">
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 数据
    datainfo: {
      type: Object,
      default: () => { }
    },
    // 外部渠道
    externalorderType: {
      type: String,
      default: ''
    },
  },
}
</script>
<style lang="scss" scoped>
.test-info-inner {
  width: 100%;
  border: 1px solid #E4ECFD;
  border-radius: 4px;
  color: #111;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.test-info-inner-box {
  margin-top: 17px;
  display: flex;
}

.text_sku_title {
  width: 69px;
  max-width: 69px;
  min-width: 69px;
}

.sku_inner {
  display: flex;
  flex: 1;
  align-items: center;
  background: #F5F6FA;
  padding: 0px 32px 17px 14px;
  box-sizing: border-box;
}

.test-info-inner-line {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;

  >p {
    text-align: left;
    box-sizing: border-box;
  }

  .test-info-inner-green {
    padding-left: 0;
    box-sizing: content-box;
    color: #0981FE;
    text-align: center;
  }
}

.flex-style {
  flex: 1;
  flex-flow: row wrap;
  height: auto;
  justify-content: start;
  border: 0;

  >p {
    padding-top: 17px;
    color: #000;
    font-weight: 500;
    margin-right: 20px;
  }
}


.pl-6 {
  padding-left: 6px;
}

.empty {
  text-align: center;
  color: #999999;
  padding-top: 17px;
}

.title_color_err {
  color: #FF687B !important;
}
</style>
